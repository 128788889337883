@import '../../node_modules/@stronghold/bootstrap/scss/customs';
@import '../../node_modules/@stronghold/bootstrap/scss/functions';
@import '../../node_modules/@stronghold/bootstrap/scss/variables';
@import '../../node_modules/@stronghold/bootstrap/scss/mixins';
@import '../../node_modules/@stronghold/bootstrap/scss/utilities';
@import '../../node_modules/@stronghold/bootstrap/scss/reboot';
@import '../../node_modules/@stronghold/bootstrap/scss/type';
@import '../../node_modules/@stronghold/bootstrap/scss/buttons';
@import '../../node_modules/@stronghold/bootstrap/scss/list-group';
@import '../../node_modules/@stronghold/bootstrap/scss/badge';
@import '../../node_modules/@stronghold/bootstrap/scss/forms';
@import '../../node_modules/@stronghold/bootstrap/scss/spinners';
@import '../../node_modules/@stronghold/bootstrap/scss/customs-theme';

@import "layout";
@import '../../node_modules/react-phone-input-2/lib/style.css';

// Phone number
.react-tel-input {
    font-family: inherit !important;
    font-size: inherit !important;
    padding: 0 !important;

    > input {
        @extend .form-control;
        padding-left: 54px+24px !important;
        height: 100%;
    }

    > .flag-dropdown {
        @extend .rounded-left;
        border-width: 0 !important;
        background-color: $gray-200 !important;

        > .selected-flag {
            padding: 0 1rem !important;
            width: 55px !important;
        }

        > .selected-flag:hover {
            background-color: $white !important;
        }
    }
}

.input-group-prepend .btn {
    z-index: 1 !important;
}

.PhoneInput {
    @extend .form-control;
    @extend .px-0;
    @extend .pl-1;
    @extend .d-flex;

    .PhoneInputCountry {
        @extend .px-2;
        @extend .m-0;

        .PhoneInputCountryIcon {
            @extend .shadow-none;
        }

        .PhoneInputCountrySelectArrow {
            border-bottom: 1px solid;
            border-right: 1px solid;
        }

        img {
            vertical-align: top;
        }
    }

    .PhoneInputInput {
        @extend .form-control;
        @extend .px-2;

        border-bottom-width: 0;
        margin-bottom: 1px;
    }

    &.is-invalid {
        > div {
            border-bottom-color: $danger;
        }

        > * {
            @extend .is-invalid;
        }
    }
}