.dropin-overlay {
    @extend .d-flex, .align-items-center;
    min-height: 100%;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.85) !important;

    > .dropin-container {
        min-width: 320px;
        width: 375px;
        height: 580px;
        background-color: #ffffff;
        border: 1px solid rgb(17, 17, 17);
        border-radius: 10px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        margin: auto;
        text-align: center;

        &.mobile {
            @extend .vh-100, .vw-100;
            border: none;
            border-radius: 0;
            box-shadow: none;
        }

        .exit {
            position: relative;
            float: right;
            top: 0;
            right: 0;

            .exit-button {
                position: absolute;
                height: 12px;
                width: 12px;
                background-color: black;
                top: 16px;
                right: 16px;
                mask: url("/images/exit-icon.svg") no-repeat center;
                -webkit-mask: url("/images/exit-icon.svg") no-repeat center;
            }
        }

        > .dropin-layout {
            @extend .p-3, .p-md-4, .h-100, .w-100;
        }
    }
}

.steps-list {
    > .steps-item {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .px-3;
        @extend .py-2;

        > .steps-number {
            @extend .badge;
            @extend .badge-primary;
            @extend .badge-pill;
            @extend .p-2;
            @extend .mr-3;
            width: 28px;
            height: 28px;
        }

        > .steps-text {
            @extend .flex-fill;
            @extend .text-left;
        }
    }
}

.tsep-cardHolderName input {
    width: 85%
}

.tsep-cardHolderName input:focus {
}

.tsep-cardNum input {
    width: 85%
}

.tsep-cardNum input:focus {
}

.tsep-datePicker input {
    width: 85%
}

.tsep-datePicker input:focus {
}

.tsep-cvv2 input {
    width: 85%
}

.tsep-cvv2 input:focus {
}

.tsep-zipCode input {
    width: 85%
}

.tsep-zipCode input:focus {
}